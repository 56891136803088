import { Variables } from "../vars";

export const developmentVars: Partial<Record<Variables, string>> = {
  REACT_APP_ANALYTICS_DASHBOARD_URL:
    "https://app.datagol.ai/app/0afc0660-e5ab-47eb-b723-6edc7ef53be0/page/ec3338df-5635-4d0e-bf0b-a76d05c60269",
  REACT_APP_CLIENT_ORIGINS:
    "https://dev.remo.co,https://dev.virtual.events.com",
  REACT_APP_API_HOST: "https://dev.remo.co/api/internal",
  REACT_APP_ASSETS_URL: "https://remo-conference-dev.appspot.com/assets/",
  REACT_APP_AWS_REGION: "us-east-1",
  REACT_APP_CHARGEBEE_SITE: "remoapp-test",
  REACT_APP_DAILY_API_URL: "https://remo-test.daily.co",
  REACT_APP_DATADOG_CLIENT_TOKEN: "pube1099a2f32a5af9dd1f4d1d204ef53d6",
  REACT_APP_EXIT_PAGE: "https://hi.remo.co/event-exit-page",
  REACT_APP_FIREBASE_API_KEY: "AIzaSyDz1unX3AN0l97pXYbzlbKevWXwB3aOK2k",
  REACT_APP_FIREBASE_AUTH_DOMAIN: "dev.remo.co",
  REACT_APP_FIREBASE_DB_URL: "https://remo-conference-dev.firebaseio.com",
  REACT_APP_FIREBASE_ID: "369359627923",
  REACT_APP_FIREBASE_PROJECT_ID: "remo-conference-dev",
  REACT_APP_FIREBASE_STORAGE_BUCKET: "remo-conference-dev.appspot.com",
  REACT_APP_FRESH_CHAT_HOST: "https://wchat.freshchat.com",
  REACT_APP_FRESH_CHAT_TOKEN: "5fb1046b-e419-4ecb-85fb-2da818cdcae3",
  REACT_APP_GCLOUD_PROJECT_NAME: "remo-conference-dev",
  REACT_APP_GIPHY_SDK_KEY: "DWNqNX0WWZOLTveCkokGdV7y6wqjrlIW",
  REACT_APP_GRAPHQL_HTTP_ENDPOINT: "https://dev.remo.co/api/graphql",
  REACT_APP_GRAPHQL_WS_ENDPOINT: "wss://dev.remo.co/api/graphql",
  REACT_APP_HEADWAY_ACCOUNT: "JmOXVx",
  REACT_APP_MIRO_CLIENT_ID: "3074457350118269469",
  REACT_APP_PRICING_PAGE_URL:
    "https://storage.googleapis.com/remo-web/dev.html",
  REACT_APP_REMO_CNAME_HOSTS: "custom-staging.remo.co",
  REACT_APP_REMO_HOST: "dev.remo.co",
  REACT_APP_SEGMENT_WRITE_KEY: "dmd2sqdzTbVMIyKLfk08mhEne1NyGtwH",
  REACT_APP_SPLIT_IO_API_KEY: "v6vsu61g40l93hu851e0oak39sb86l5dlamb",
  REACT_APP_USERPILOT_APP_TOKEN: "5ix90o6",
  REACT_APP_VPN_DETECTION_API_KEY: "86ff1879952e42c69419ed0e512fc874",
};
